import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Submit({ icon, name }) {
  return (
    <div className="submit">
      <button type="submit">
        <div>
          <span>{name}</span>
          <FontAwesomeIcon icon={icon} />
        </div>
      </button>
    </div>
  );
}

export default Submit;
