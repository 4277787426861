import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faCheck,
  faSpinnerThird,
} from "@fortawesome/pro-light-svg-icons";

const Msg = ({ data }) => {
  return (
    <div className={`msg ${data.type}`}>
      {data.type === "error" ? (
        <FontAwesomeIcon icon={faTimes} size="lg" />
      ) : data.type === "success" ? (
        <FontAwesomeIcon icon={faCheck} size="lg" />
      ) : (
        <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin />
      )}
      <span>{data.text}</span>
    </div>
  );
};

export default Msg;
