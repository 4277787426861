import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faSquareCheck } from "@fortawesome/pro-light-svg-icons";

function InputCheckbox({ label, name, checked, click }) {
  return (
    <button
      type="button"
      onClick={() => click(name, !checked)}
      className="checkbox"
    >
      <div>
        <FontAwesomeIcon icon={checked ? faSquareCheck : faSquare} />
        <span>{label}</span>
      </div>
    </button>
  );
}

export default InputCheckbox;
