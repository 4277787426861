import "./styles.css";

function TextArea({ name, label, req, error, autocomplete = "off" }) {
  return (
    <div className="textarea">
      <label htmlFor={name}>
        {label} {req && <span className="error">*</span>}
      </label>
      <textarea
        id={name}
        name={name}
        defaultValue=""
        autoComplete={autocomplete}
        className={error ? "error-border" : ""}
      />
    </div>
  );
}

export default TextArea;
