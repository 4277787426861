import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Alert from "./shared/Alert/index";

/* uncomment for testing locally
window.opformSettings = {
  id: "general", // general, property
  title: "Thunder Valley Ranch",
  agents: "rbross@outdoorproperties.com|",
  closed: false,
};
*/

if (document.getElementById("opform-root")) {
  const root = ReactDOM.createRoot(document.getElementById("opform-root"));

  let element = (
    <Alert data={{ type: "error", text: "An error has occurred." }} />
  );

  if (window.opformSettings) {
    const { id, title, agents, closed } = window.opformSettings;

    if (id === "general") {
      element = closed ? (
        <Alert
          data={{
            type: "error",
            text: "Contact form is currently closed.",
          }}
        />
      ) : (
        <App form={id} title={title} agents={agents} />
      );
    } else if (id === "property") {
      element = closed ? (
        <Alert
          data={{
            type: "error",
            text: "Property inquiry form is currently closed.",
          }}
        />
      ) : (
        <App form={id} title={title} agents={agents} />
      );
    }
  }

  root.render(<React.StrictMode>{element}</React.StrictMode>);
}
