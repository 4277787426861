import { useState, useRef, createRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { API_URL, RECAPTCHA_KEY } from "../../utils/index";

import Alert from "../../shared/Alert/index";
import InputText from "../../shared/InputText/index";
import TextArea from "../../shared/TextArea/index";
import Msg from "../../shared/Msg/index";
import Submit from "../../shared/Submit/index";
import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

function PropContact({ title, agents }) {
  const [errors, setErrors] = useState([]);
  const [names, setNames] = useState({});
  const [msg, setMsg] = useState({});
  const [alert, setAlert] = useState({});

  const recaptchaRef = createRef();
  const formElement = useRef(null);

  const handleUpdate = (name, value) => {
    setNames((names) => ({ ...names, [name]: value ? value : "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors([]);
    setMsg({
      type: "working",
      text: "",
    });

    let recaptcha = names.recaptcha
      ? names.recaptcha
      : await recaptchaRef.current.executeAsync();

    const url = new URL(`${API_URL}/?f=contact`);
    const formData = new FormData(formElement.current);
    formData.append("type", "property");
    formData.append("title", title);
    formData.append("agents", agents);
    formData.append("recaptcha", recaptcha);

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        setAlert({
          type: "success",
          text: json.text,
        });
      } else {
        if (json.fields[0] === "recaptcha") {
          handleUpdate("recaptcha", "");
          recaptchaRef.current.reset();
        } else {
          handleUpdate("recaptcha", recaptcha);
        }
        setErrors(json.fields);
        setMsg({
          type: "error",
          text: json.text,
        });
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      });
    }
  };

  return (
    <>
      {alert.type ? (
        <Alert data={alert} />
      ) : (
        <form
          ref={formElement}
          method="post"
          action="/"
          onSubmit={(e) => handleSubmit(e)}
        >
          <div className="form-row-one">
            <InputText
              name="name"
              label="Name"
              req={true}
              error={errors.includes("name") ? true : false}
              autocomplete="name"
            />
          </div>

          <div className="form-row-one">
            <InputText
              name="email"
              label="Email Address"
              req={true}
              error={errors.includes("email") ? true : false}
              autocomplete="email"
            />
          </div>

          <div className="form-row-one">
            <TextArea
              name="comments"
              label="Question / Comments"
              req={true}
              error={errors.includes("comments") ? true : false}
              autocomplete="off"
            />
          </div>

          <div className="form-row-one" style={{ marginTop: "15px" }}>
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={RECAPTCHA_KEY}
              badge="inline"
            />
          </div>

          <div className="form-msg-submit">
            {msg.type && <Msg data={msg} />}
            {msg.type === "working" ? null : (
              <Submit name="Submit Inquiry" icon={faChevronCircleRight} />
            )}
          </div>

          <p style={{ marginTop: "9.5px", lineHeight: "20px" }}>
            <small style={{ fontSize: "95%" }}>
              We will not share your email address with third-parties. For more
              information on how we use your information, see our{" "}
              <a href="/privacy-policy/" target="_blank" rel="noopener">
                Privacy Policy
              </a>
              .
            </small>
          </p>
        </form>
      )}
    </>
  );
}

export default PropContact;
