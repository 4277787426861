import GenContact from "./pages/GenContact/index";
import PropContact from "./pages/PropContact/index";

function App({ form, title, agents }) {
  return (
    <>
      {form === "general" ? (
        <GenContact />
      ) : (
        <PropContact title={title} agents={agents} />
      )}
    </>
  );
}

export default App;
