import "./styles.css";

function InputText({
  type = "text",
  name,
  label,
  req,
  error,
  autocomplete = "off",
}) {
  return (
    <div className="input-text">
      <label htmlFor={name}>
        {label} {req && <span className="error">*</span>}
      </label>
      <input
        type={type}
        id={name}
        name={name}
        defaultValue=""
        autoComplete={autocomplete}
        className={error ? "error-border" : ""}
      />
    </div>
  );
}

export default InputText;
